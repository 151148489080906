import "./App.css";
import "./assets/css/style.css";
import React, { useEffect } from "react";
import Router from "./app/router/routes";
import Auth from "./store/slice/auth";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const auth = Auth();
  const dispatch = useDispatch();

  const xhibitStorageString = localStorage.getItem("xhibit-storage");
  const xhibitStorage = JSON.parse(xhibitStorageString);

  useEffect(() => {
    if (xhibitStorage && xhibitStorage.token) {
      auth.setToken(localStorage.getItem("token"));
    }
    if (xhibitStorage && xhibitStorage.isLoggedIn) {
      dispatch(
        auth.setLoggedIn(JSON.parse(xhibitStorage && xhibitStorage.isLoggedIn))
      );
    }
    // eslint-disable-next-line
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedIsLoggedIn = xhibitStorage && xhibitStorage.isLoggedIn;
    dispatch(auth.setLoggedIn(storedIsLoggedIn));
  }, [isLoggedIn]);

  const isLoggedin = isLoggedIn || (xhibitStorage && xhibitStorage.token);

  return (
    <React.Suspense>
      <Router isLoggedIn={isLoggedin} />
    </React.Suspense>
  );
}

export default App;

import axios from "axios";
import config from "./config";

const BASE_URL = config.baseApi;

const ServerCall = () => {
  const xhibitStorageString = localStorage.getItem("xhibit-storage");
  const xhibitStorage = JSON.parse(xhibitStorageString);

  const customAxios = axios.create({
    baseURL: BASE_URL,
  });

  const requestHandler = (request) => {
    const token = xhibitStorage && xhibitStorage.token;
    request.headers.Authorization = `Bearer ${token}`;

    return request;
  };

  const responseHandler = (response) => {
    if (response.status === 401 || response.status === 403) {
      window.location = "/";
      localStorage.removeItem(xhibitStorage.token);
    }
    return response;
  };

  const requestErrorHandler = (error) => {
    return Promise.reject(error);
  };

  const responseErrorHandler = (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("token");
        localStorage.clear();
        if (!error.config.url.includes("/auth/login?email")) {
          window.location = "/";
        }
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  };

  customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => requestErrorHandler(error)
  );

  customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    responseErrorHandler
  );
  return { customAxios };
};

export default ServerCall;

import { enqueueSnackbar } from "notistack";
import ServerCall from "../../serverCall";
import { get } from "lodash";
import { ANCHORORIGIN, ERROR } from "../utils/constants";

const AuthService = () => {
  const serverCall = ServerCall();

  const Login = async (loginPage) => {
    const { email, password } = loginPage;
    // const newPass = encodeURIComponent(password);

    const body = {
      email,
      password,
    };

    const response = await serverCall.customAxios.post(
      `/auth/login-admin`,
      body
    );

    const data = get(response, "data.token", null);
    const Email = get(response, "data.data.email", null);
    const ID = get(response, "data.data._id", null);
    const Role = get(response, "data.data.roles", null);
    const ProfileCompleted = get(response, "data.data.isProfileComplete", null);

    const xhibitStorage = {
      token: data,
      email: Email,
      isLoggedIn: true,
      id: ID,
      role: Role,
      profile: ProfileCompleted,
    };

    localStorage.setItem("xhibit-storage", JSON.stringify(xhibitStorage));

    return response;
  };

  const forgotPasssword = (email) => {
    const response = serverCall.customAxios.post(
      `/auth/forgotPassword?email=${email}`
    );
    return response;
  };

  const verifyOTP = (email, otp) => {
    const response = serverCall.customAxios.post(
      `/auth/verifyForgotPwdOTP?email=${email}&otp=${otp}`
    );
    return response;
  };

  const setNewPassword = (email, newPassword, token) => {
    const response = serverCall.customAxios.post(
      `/auth/verifyForgotPwdToken?email=${email}&newPassword=${newPassword}&token=${token}`
    );
    return response;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
  };

  return {
    Login,
    logout,
    forgotPasssword,
    verifyOTP,
    setNewPassword,
  };
};

export default AuthService;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import AuthService from "../../app/services/auth.service";

const Auth = () => {
  const authService = AuthService();
  const initialState = {
    loading: false,
    token: null,
    error: false,
    user: null,
    isLoggedIn: !!JSON.parse(localStorage.getItem("isLoggedIn")),
  };

  const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      setLoggedIn: (state, action) => {
        state.isLoggedIn = action.payload;
      },
      setToken: (state, action) => {
        state.token = action.payload;
      },
      setUser: (state, action) => {
        state.user = action.payload;
        state.token = get(action.payload, "token", null);
        localStorage.setItem(
          "token",
          JSON.stringify(get(action.payload, "token", null))
        );
      },
    },
  });

  const logout = createAsyncThunk("auth/logout", () => {
    AuthService.logout();
  });

  const login = createAsyncThunk(
    "/auth/login-admin",
    async (requestObj, thunkAPI) => {
      try {
        const response = await authService.Login(requestObj);
        const token = get(response, "data.apiresponse.data.token", null);
        return token;
      } catch (error) {
        return thunkAPI.rejectWithValue(
          get(error, "response.data.apierror", error)
        );
      }
    }
  );

  const { reducer, actions } = authSlice;
  const { setToken, setUser, setLoggedIn } = actions;
  return { login, logout, actions, setToken, setUser, setLoggedIn, reducer };
};

export default Auth;
// const { reducer, actions } = authSlice;
// export const { setToken, setUser, setLoggedIn } = actions;
// export default reducer;

import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";

const Loading = (Component) => (props) =>
  (
    <Suspense fallback={<withLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Application = Loading(lazy(() => import("../components/Application")));
const Login = Loading(
  lazy(() => import("../components/Authentication/Login.js"))
);
const NotFoundPage = Loading(
  lazy(() => import("../components/Session/NotFound"))
);

const User = Loading(lazy(() => import("../components/User/User.js")));
const Category = Loading(
  lazy(() => import("../components/Category/Category.js"))
);
const ViewLookbook = Loading(
  lazy(() => import("../components/Lookbooks/ViewLookbook.js"))
);
const Lookbooks = Loading(
  lazy(() => import("../components/Lookbooks/Lookbooks.js"))
);
const Featured = Loading(
  lazy(() => import("../components/Featured/Featured.js"))
);
const AllLookbooks = Loading(
  lazy(() => import("../components/AllLookbooks/AllLookbooks.js"))
);

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <Application /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        element: <RedirectionWrapper to="/lookbooks/" />,
        index: true,
      },
      {
        path: "/user/",
        element: <User />,
      },
      {
        path: "/adminlookbooks/",
        element: <Lookbooks />,
      },
      {
        path: "/category/",
        element: <Category />,
      },
      {
        path: "/featured/",
        element: <Featured />,
      },
      {
        path: "/adminlookbooks/:id",
        element: <ViewLookbook />,
      },
      {
        path: "/alllookbooks/",
        element: <AllLookbooks />,
      },
    ],
  },

  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <RedirectionWrapper to="/lookbooks/" />,
  },
  { path: "*", element: <NotFoundPage /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}

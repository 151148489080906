export const SUCCESS = "success";
export const ERROR = "error";
export const ANCHORORIGIN = {
  horizontal: "right",
  vertical: "bottom",
};
export const PAGELIMIT = 10;
export const EMPTY = "";

export const FORMIKERROR = {
  fontSize: "12px",
  color: "red",
  marginTop: "3px",
};

export const CUSTOMDROPDOWNSTYLE = {
  option: (provided, state) => ({
    ...provided,
    ":hover": {
      color: "#000000de",
    },
  }),
};

export const CUSTOM_STYLES = {
  headCells: {
    style: {
      position: "sticky",
      right: 0,
      "&:last-child": {
        backgroundColor: "#ffffff", // Set the background color for the last column
      },
    },
  },
  cells: {
    style: {
      position: "sticky",
      right: 0,
    },
  },
  rows: {
    style: {
      ":hover": {
        backgroundColor: "#EEEEEE !important",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#fafafa", // Set the background color for even rows
      },
      "&:nth-child(even)": {
        backgroundColor: "#FFFFFF", // Set the background color for odd rows
      },
      "&:nth-child(even) > :last-child": {
        backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
      },
      "&:nth-child(odd) > :last-child": {
        backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
      },

      "&:nth-child(even):hover": {
        "& > :last-child": {
          backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
        },
      },
      "&:nth-child(odd):hover": {
        "& > :last-child": {
          backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
        },
      },
    },
  },
};
